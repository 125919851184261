const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  ROL_BEGIN: 'ROL_BEGIN',
  ROL_SUCCESS: 'ROL_SUCCESS',
  ROL_ERR: 'ROL_ERR',

  SET_USER: 'SET_USER',
  SET_ROL: 'SET_ROL',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },

  rolSuccess: (data) => {
    return {
      type: actions.ROL_SUCCESS,
      data,
    };
  },

  rolErr: (err) => {
    return {
      type: actions.ROL_ERR,
      err,
    };
  },

  rolBegin: () => {
    return {
      type: actions.ROL_BEGIN,
    };
  },

  setUser: (user) => {
    return {
      type: actions.SET_USER,
      user,
    };
  },

  setRol: (rol) => {
    return {
      type: actions.SET_ROL,
      rol,
    };
  },
};

export default actions;
