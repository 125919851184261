import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import useItemsMenu from './MenuItems';

const { SubMenu } = Menu;

function NavMobile({ darkMode, toggleCollapsed, topMenu, classBgColor }) {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const menuItems = useItemsMenu(path);
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      if (item.subMenu) {
        return (
          <SubMenu key={item.name} title={item.name} icon={!topMenu && <FeatherIcon icon="menu" />}>
            {renderMenuItems(item.subMenu)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={item.name}>
          <NavLink onClick={toggleCollapsed} to={item.path}>
            {item.name}
          </NavLink>
        </Menu.Item>
      );
    });
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      className={classBgColor}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {renderMenuItems(menuItems)}
    </Menu>
  );
}

NavMobile.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  classBgColor: propTypes.string,
  //   menuItems: propTypes.arrayOf(
  //     propTypes.shape({
  //       name: propTypes.string.isRequired,
  //       path: propTypes.string.isRequired,
  //       roles: propTypes.arrayOf(propTypes.string).isRequired,
  //       subMenu: propTypes.arrayOf(
  //         propTypes.shape({
  //           name: propTypes.string.isRequired,
  //           path: propTypes.string.isRequired,
  //           roles: propTypes.arrayOf(propTypes.string).isRequired,
  //         }),
  //       ),
  //     }),
  //   ).isRequired,
};

export default NavMobile;
