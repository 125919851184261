import actions from './actions';

const { SET_NOTIFICATIONS, SET_NOTIFICATIONS_ERR } = actions;

const initialStateFilter = {
  data: [],
  total: 0,
  error: null,
};

const readNotificationsReducer = (state = initialStateFilter, action) => {
  const { type, payload, err } = action;
  switch (type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        data: [...payload.data],
        total: payload.total,
      };
    case SET_NOTIFICATIONS_ERR:
      return {
        data: [],
        total: 0,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { readNotificationsReducer };
