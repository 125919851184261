// import { useRouteMatch } from 'react-router-dom';
import { useState, useEffect } from 'react';

const useItemsMenu = (path = '') => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (path) {
      setMenuItems([
        {
          name: 'Conéctate con el Humboldt',
          path: `${path}/conectate`,
          roles: ['user','admin'],
        },
        {
          name: '¿Cómo operamos?',
          path: `${path}/como-operamos`,
          roles: ['admin', 'user'],
          subMenu: [
            {
              name: 'Lineamientos para la operación',
              path: `${path}/sidg/procesos`,
              roles: ['admin', 'user'],
            },
            {
              name: 'Carga de documentos',
              path: `${path}/sidg/documentos`,
              roles: ['admin', 'sig'],
            },
            {
              name: 'Administrador documentos',
              path: `${path}/sidg/list-documents`,
              roles: ['admin', 'sig'],
            },
          ],
        },
        {
          name: 'Gestión de solicitudes ',
          path: `${path}/plataformas/gestion-solicitudes`,
          roles: ['admin','contabilidad','financiera','logistica','logistica_area','financiera_senior','investigadores','tesoreria','directivos','user'],
        },
        {
          name: 'Herramientas de trabajo',
          path: `${path}/plataformas/plataformas-institucionales`,
          roles: ['user', 'admin'],
        },
       
        {
          name: 'Listas',
          path: `#`,
          roles: ['admin'],
          subMenu: [
            {
              name: 'Proyecto',
              path: `${path}/list/project/table`,
              roles: ['admin'],
            },
            {
              name: 'Producto',
              path: `${path}/list/project-product/table`,
              roles: ['admin'],
            },
            {
              name: 'Producto en linea',
              path: `${path}/list/project-product-inline/table`,
              roles: ['admin'],
            },
            {
              name: 'Fuente',
              path: `${path}/list/source/table`,
              roles: ['admin'],
            },
            {
              name: 'Agencia',
              path: `${path}/list/agency/table`,
              roles: ['admin'],
            },
            {
              name: 'Usuarios',
              path: `${path}/users/dataTable`,
              roles: ['admin'],
            },
          ],
        },
        
      ]);
    }
  }, [path]);

  return menuItems;
};

export default useItemsMenu;
