import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import Notification from './notification';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';

function AuthInfo() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => {
    return {
      user: state.auth.user, // dentro de redux/authentication/reducerdes esta definido el SET_USER por eso el acceso es por medio de state(react).auth(El reducer AuthReducer es el que lo maneja y se invoca con auth).user(La variable que yo manejé)
    };
  });
  const { rol } = useSelector((state) => {
    return {
      rol: state.auth.rol,
    };
  });
  // console.log("ROLES EN REDUX:");
  // console.log(rol);

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={user.picture} alt="" width="50px" />
          <figcaption>
            <Heading as="h5">{user.name}</Heading>
            <p>{user.email}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdown__links">
  {rol.map((userol, index) => (
    <li key={index}>
      <Link>
        Rol: {userol.name}
      </Link>
    </li>
  ))}
</ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Notification />
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            {user && user.picture ? (
              <Avatar src={user.picture} />
            ) : (
              <Avatar src="../../../static/img/avatar/chat-auth.png" />
            )}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;