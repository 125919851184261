import moment from 'moment';
import { UrlGestion, UrlPlataformaIns } from '../routes/admin/plataformas';
import { UrlOperamos } from '../routes/admin/operation';

export function changeDateFormat(date) {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return formattedDate;
}

export const GetClassHeader = (url) => {
  if (url === UrlGestion) return 'navbar-green-main';
  if (url === UrlPlataformaIns) return 'navbar-greenDark';
  if (url === '/admin') return 'navbar-yelow';
  if (url === UrlOperamos) return 'navbar-grey';

  return '';
};

export const notificationSuccess = (type, isUpdate, notification) => {
  let mensaje = 'El registro se ha creado satisfactoriamente';
  if (isUpdate) {
    mensaje = 'El registro se ha actualizado satisfactoriamente';
  }
  notification[type]({
    message: '¡Registro Exitoso!',
    description: mensaje,
  });
};

export const notificationError = (type, notification, description) => {
  notification[type]({
    message: 'Error ',
    description,
  });
};
