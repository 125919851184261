import * as FileSaver from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { notification } from 'antd';
import axiosInstance from '../axiosInstance';
import { DEFAULT_COLOR } from '../container/sidg/Processes/ReChartPie';

function convertItemToProject(item) {
  return {
    id: item.id,
    title: item.nombre_documento,
    status: item.es_eliminado,
    content: item.tipo_documento,
    category: item.descp_version,
    imageUrl: item.url_imagen,
    viewUrl: item.url_archivo,
  };
}
function groupedByDocumentType(results) {
  const additionalInfo = [];

  results.forEach((item) => {
    item.subprocesos.forEach((subproceso) => {
      const subprocesoInfo = {
        id_adm_sbproceso: subproceso.id_adm_sbproceso,
        subproceso: subproceso.subproceso,
        objetivo: subproceso.sbpro_objetivo,
        documentosPorTipo: {},
      };
      additionalInfo.push(subprocesoInfo);

      subproceso.documentos.forEach((documento) => {
        const tipoDocumento = documento.tipo_documento;

        if (!subprocesoInfo.documentosPorTipo[tipoDocumento]) {
          subprocesoInfo.documentosPorTipo[tipoDocumento] = [];
        }

        subprocesoInfo.documentosPorTipo[tipoDocumento].push({
          tipo_documento: tipoDocumento,
          documento: convertItemToProject(documento),
        });
      });
    });
  });
  return additionalInfo;
}

function getMappedDocuments(results) {
  const documents = results[0]?.subprocesos?.reduce((acc, subProcess) => {
    const { documentos = [] } = subProcess || {};
    const mappedDocuments = documentos.map((document) => {
      return {
        process: subProcess.subproceso,
        name: document.nombre_documento,
        documentType: document.tipo_documento,
        file: document.url_archivo,
        image: document.url_imagen,
      };
    });
    return [...acc, ...mappedDocuments];
  }, []);

  return documents;
}

export function getMainProcesses() {
  return axiosInstance.get(`/listadmnproceso`).then((response) => {
    return response.data.map((process) => {
      return {
        ...process,
        name: process.nombre,
        color: process.color || DEFAULT_COLOR,
        value: 30,
      };
    });
  });
}

export function getSecondaryProcesses(idProcess) {
  return axiosInstance.get(`/listadmproceso/${idProcess}`);
}

export function getFiles(idProcess) {
  return axiosInstance.get(`/tipodocmproceso/${idProcess}`).then((response) => {
    return {
      filesData: getMappedDocuments(response.data.data),
      groupedFilesData: groupedByDocumentType(response.data.data),
    };
  });
}

export function getFile(url) {
  // Asegurarse de que la URL comience con https
  if (!url.startsWith('https://')) {
    // Si la URL comienza con http, reemplazarla con https
    if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://');
    } else {
      // Si la URL no tiene http o https, agregar https
      url = `https://${url}`;
    }
  }

  notification.open({
    message: 'Descarga iniciada',
    description: 'La descarga del archivo ha comenzado. Por favor, espera...',
    duration: 8,
  });

  return axiosInstance
    .get(url, { responseType: 'arraybuffer' })
    .then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      FileSaver.saveAs(blob, uuidv4());
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
}

export function getFileName(url, name) {
  // Verifica si la URL empieza con 'https', si no, la modifica para que así sea
  if (!url.startsWith('https://')) {
    if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://');
    } else {
      url = `https://${url}`;
    }
  }

  notification.open({
    message: 'Descarga iniciada',
    description: 'La descarga del archivo ha comenzado. Por favor, espera...',
    duration: 8,
  });

  return axiosInstance
    .get(url, { responseType: 'arraybuffer' })
    .then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      FileSaver.saveAs(blob, name);
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
      notification.error({
        message: 'Error',
        description: 'Hubo un problema al descargar el archivo.',
        duration: 8,
      });
    });
}

export function getFileNameURL(url) {
  console.log(url);
  // Verifica si la URL empieza con 'https', si no, la modifica para que así sea
  if (!url.startsWith('https://')) {
    if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://');
    } else {
      url = `https://${url}`;
    }
  }

  console.log('Descarga');
  console.log(url);

  notification.open({
    message: 'Descarga iniciada',
    description: 'La descarga del archivo ha comenzado. Por favor, espera...',
    duration: 8,
  });

  return axiosInstance
    .get(url, { responseType: 'arraybuffer' })
    .then((response) => {
      // Extraer el nombre del archivo desde el encabezado Content-Disposition
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file'; // Valor predeterminado

      if (contentDisposition) {
        const [, matchedFileName] = contentDisposition.match(/filename="(.+)"/) || [];
        if (matchedFileName) {
          fileName = matchedFileName;
        }
      } else {
        // Extraer el nombre del archivo desde la URL
        const urlSegments = url.split('/');
        fileName = urlSegments[urlSegments.length - 1];
      }

      console.log(fileName);

      // Crea el blob y guarda el archivo con el nombre correcto
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      FileSaver.saveAs(blob, fileName);
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
      notification.error({
        message: 'Error',
        description: 'Hubo un problema al descargar el archivo.',
        duration: 8,
      });
    });
}

export function downloadXLS() {
  return axiosInstance.get('/sgc/xlsx', { responseType: 'arraybuffer' }).then((response) => {
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    FileSaver.saveAs(blob, uuidv4());
  });
}
