import React, { lazy } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../../components/utilities/auth-info/protectedroute';

const Users = lazy(() => import('../../container/pages/Users'));
const AddUser = lazy(() => import('../../container/pages/AddUsers'));
const DataTable = lazy(() => import('../../container/pages/UserListDataTable'));
const Team = lazy(() => import('../../container/pages/Team'));
const CreateUserForm = lazy(() => import('../../container/travels/overview/createUser'));

function PagesRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/add-user`} component={AddUser} allowedRoles={['admin']}/>
      <ProtectedRoute path={`${path}/dataTable`} component={DataTable} allowedRoles={['admin']}/>
      <ProtectedRoute path={`${path}/team`} component={Team} allowedRoles={['admin']}/>
      <ProtectedRoute path={`${path}/create`} component={CreateUserForm} allowedRoles={['admin']}/>
      <ProtectedRoute path={`${path}`} component={Users} allowedRoles={['admin']}/>
    </Switch>
  );
}

export default PagesRoute;
