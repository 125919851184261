import axios from 'axios';
import { store } from './redux/store';
import { logOut } from './redux/authentication/actionCreator';

const serviceEndpoint = process.env.REACT_APP_API_ENDPOINT;

const axiosInstance = axios.create({
  baseURL: serviceEndpoint
})

// TODO Integrate or delete with the config/dataService/dataService.js axios instance


function isTokenExpired(expirationTimestamp) {
  return (Date.now() >= expirationTimestamp * 1000)
}

axiosInstance.interceptors.request.use(function (config) {
  const { autNotNeeded } = config;
  const { auth = {} } = store.getState();
  const { laravel: token, exp: expirationTimestamp  } = auth?.user || {};

  if (!autNotNeeded) {
    if(isTokenExpired(expirationTimestamp)) {
      
      store.dispatch(logOut())
    }
  }

  if (token) config.headers.Authorization = token ? `Bearer ${token}` : '';

  // Detect if the request has files and set Content-Type to multipart/form-data
  // if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  // }

  return config;
});


export default axiosInstance