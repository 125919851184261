import Cookies from 'js-cookie';
import actions from './actions';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR, SET_USER, ROL_BEGIN,ROL_SUCCESS,ROL_ERR,SET_ROL } = actions;

const initState = {
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
  user: null,
  rol: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ROL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ROL_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
      case LOGIN_SUCCESS:
        return {
          ...state,
          login: data,
          loading: false,
        };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
      case ROL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SET_USER: // manejo de la acción SET_USER
      console.trace(action.user);
      return {
        ...state,
        user: action.user, // actualiza el usuario en el estado
      };
    case SET_ROL:
      console.trace(action.rol);
      return {
        ...state,
        rol: action.rol, // actualiza el rol en el estado
      };
    default:
      return state;
  }
};
export default AuthReducer;
