import React, { lazy } from 'react';
import {  Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../../components/utilities/auth-info/protectedroute';



const PlataformaForm = lazy(() => import('../../container/plataformas/overview/PlataformaForm'));
const PlataformaSolicitudes = lazy(() => import('../../container/plataformas/overview/PlataformaSolicitudes'));


export const UrlGestion = `/admin/plataformas/gestion-solicitudes`
export const UrlPlataformaIns = `/admin/plataformas/plataformas-institucionales`

function PlataformaRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/plataformas-institucionales`} component={PlataformaForm}  allowedRoles={['user']}/>
      <ProtectedRoute path={`${path}/gestion-solicitudes`} component={PlataformaSolicitudes}  allowedRoles={['admin','contabilidad','financiera','logistica','logistica_area','financiera_senior','investigadores','tesoreria','directivos']}/>
    </Switch>
  );
}

export default PlataformaRoute;