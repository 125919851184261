import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Dashboard from './dashboard';
import Pages from './pages';
import Users from './users';
import Widgets from './widgets';
import Features from './features';
import Axios from './axios';
import Gallery from './gallery';
import withAdminLayout from '../../layout/withAdminLayout';
import { useGetNotifications } from '../../hooks/useGetNotifications';

const Projects = lazy(() => import('./projects'));
const Calendars = lazy(() => import('../../container/Calendar'));
const Inbox = lazy(() => import('../../container/notification/overview/NotificationTable'));
const Chat = lazy(() => import('../../container/chat/ChatApp'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const Firebase = lazy(() => import('./firebase'));
const ToDo = lazy(() => import('../../container/toDo/ToDo'));
const Note = lazy(() => import('../../container/note/Note'));
const Contact = lazy(() => import('../../container/contact/Contact'));
const ContactGrid = lazy(() => import('../../container/contact/ContactGrid'));
const ContactAddNew = lazy(() => import('../../container/contact/AddNew'));
const Calendar = lazy(() => import('../../container/calendar/Calendar'));
// const FileManager = lazy(() => import('../../container/fileManager/FileManager'));
const Kanban = lazy(() => import('../../container/kanban/Index'));
const Task = lazy(() => import('../../container/task/Index'));
const Sidg = lazy(() => import('./sidg'));
const Car = lazy(() => import('./car'));
const Travels = lazy(() => import('./travels'));
const Events = lazy(() => import('./events'));
const Rooms = lazy(() => import('./rooms'));
const Intranet = lazy(() => import('./intranet'));
const Plataformas = lazy(() => import('./plataformas'));
const Operation = lazy(() => import('./operation'));
const conect = lazy(() => import('./conect'));
const List = lazy(() => import('./list'));
const Anticipo = lazy(() => import('./anticipo'));

const Guest = lazy(() => import('./guest'));
const ServicesRoute = lazy(() => import('./servicelog'));

function Admin() {
  const { path } = useRouteMatch();
  // Call get notifications service
  useGetNotifications();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/sidg`} component={Sidg} />
        <Route path={`${path}/car`} component={Car} />
        <Route path={`${path}/travels`} component={Travels} />
        <Route path={`${path}/plataformas`} component={Plataformas} />
        <Route path={`${path}`} component={Operation} />
        <Route path={`${path}`} component={conect} />
        <Route path={`${path}/events`} component={Events} />
        <Route path={`${path}/notificaciones/:page`} component={Inbox} />
        <Route path={`${path}`} component={Pages} />
        <Route path={`${path}`} component={Features} />
        <Route path={`${path}`} component={Axios} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/gallery`} component={Gallery} />
        <Route path={`${path}/project`} component={Projects} />
        <Route path={`${path}/calendar`} component={Calendars} />
        <Route path={`${path}/app/kanban`} component={Kanban} />
        <Route path={`${path}/email/:page`} component={Inbox} />
        <Route path={`${path}/firestore`} component={Firebase} />
        <Route path={`${path}/main/chat`} component={Chat} />
        <Route path={`${path}/profile/myProfile`} component={Myprofile} />
        <Route path={`${path}/app/to-do`} component={ToDo} />
        <Route path={`${path}/app/note`} component={Note} />
        <Route path={`${path}/app/task`} component={Task} />
        <Route path={`${path}/contact/list`} component={Contact} />
        <Route path={`${path}/contact/grid`} component={ContactGrid} />
        <Route path={`${path}/contact/addNew`} component={ContactAddNew} />
        <Route path={`${path}/app/calendar`} component={Calendar} />
        <Route path={`${path}/widgets`} component={Widgets} />
        <Route path={`${path}/rooms`} component={Rooms} />
        <Route path={`${path}/intranet`} component={Intranet} />
        <Route path={`${path}/guest`} component={Guest} />
        <Route path={`${path}/services`} component={ServicesRoute} />
        <Route path={`${path}/list`} component={List} />
        <Route path={`${path}/anticipo`} component={Anticipo} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Admin);
