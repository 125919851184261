import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Card } from 'antd';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { rol } = useSelector((state) => state.auth);

  const userRoles = rol.map(rolItem => rolItem.name);

  // hasPermission verifica si algún rol del usuario está en allowedRoles
  const hasPermission = userRoles.some(userRole => allowedRoles.includes(userRole));

  // console.log("ROLES EN REDUX:", rol);

  return (
    <Route
      {...rest}
      render={(props) =>
        hasPermission ? (
          <Component {...props} />
        ) : (
          <div style={{ margin: "50px" }}>
          <Card
            title="Aviso Importante"
            bordered={false}
            style={{ width: 300 }}
          >
            <p>
            No cuentas con el perfil necesario para realizar esta operación, comunicate con tecnologias@humboldt.org.co
            </p>
            {/* O podrías redirigir a una página de error específica */}
          </Card>
        </div>
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  // rest no necesita ser definido en PropTypes ya que es simplemente para capturar el resto de props
};

export default ProtectedRoute;
