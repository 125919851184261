import React, { useLayoutEffect, useState , useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link, useRouteMatch } from 'react-router-dom';
import propTypes from 'prop-types';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TopMenuStyle } from './style';
import useItemsMenu from './MenuItems';
import { fetchSearchSuggestions } from '../services/guest';
import { getFileName } from '../services/processes'

const { Search } = Input;

function TopMenu({ classBgColor }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const { path } = useRouteMatch();

  const menuItems = useItemsMenu(path);
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDownload = useCallback((url,name) => {
    getFileName(url,name);
  }, []);
  
  /* const internalLinks = [
    {
      title: 'Mis solicitudes de viaje',
      url: '/admin/travels/mis-solicitudes',
      keywords: ['Mis solicitudes de gastos de viaje', 'listado']
    },
    {
      title: 'Nueva solicitud de gastos de viaje',
      url: '/admin/travels/expense-request',
      keywords: ['Nueva Solicitud de Gasto de Viaje', 'crear']
    },
    {
      title: 'Mis solicitudes de Car',
      url: '/admin/car/mis-solicitudes',
      keywords: ['Mis solicitudes Car', 'listado']
    },
    {
      title: 'Crear solicitud de Car',
      url: '/admin/car/solicitud',
      keywords: ['Crear solicitud de Car', 'nueva']
    },
    {
      title: 'Mis solicitudes de invitados',
      url: 'admin/guest/mis-solicitudes',
      keywords: ['Mis solicitudes De Invitados', 'litado']
    },
    {
      title: 'Crear solicitud gastos de invitado',
      url: '/admin/guest/solicitud-invitados',
      keywords: ['Crear solicitud Gastos De Invitado', 'nueva']
    },
    {
      title: 'Solicitud servicios para eventos o actividades y/o anticipo',
      url: '/admin/services/request',
      keywords: ['Crear solicitud servicios para eventos o actividades y/o anticipo', 'anticipo']
    },
    {
      title: 'Mis Solicitud servicios para eventos o actividades y/o anticipo',
      url: '/admin/services/mis-solicitudes',
      keywords: ['Crear solicitud servicios para eventos o actividades y/o anticipo', 'anticipo']
    },
    {
      title: 'Solicitudes Soporte',
      url: '/admin/intranet/solicitud',
      keywords: ['Crear solicitudes Soporte', 'nueva']
    },
    {
      title: 'Crear reserva de salas',
      url: '/admin/rooms/solicitud-salas',
      keywords: ['Crear reserva de salas', 'nueva']
    },
    {
      title: 'Mis reservas de salas',
      url: '/admin/rooms/room-consultation',
      keywords: ['Mis reservas de salas', 'nueva']
    },
  ]; */
  const internalLinks = [];
  const normalizeString = (str) => {
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  const handleKeyUp = async () => {
    if (searchTerm.length > 0) {
      const normalizedSearchTerm = normalizeString(searchTerm);
      const internalSuggestionData = internalLinks
        .filter(link => link.keywords.some(keyword => normalizeString(keyword).includes(normalizedSearchTerm)))
        .map(link => ({
          word: link.title,
          url: link.url,
          isExternal: false,
        }));

      setSuggestions(internalSuggestionData);

      try {
        const response = await fetchSearchSuggestions(searchTerm);
        const suggestionData = response.data.data.map((item) => ({
          word: item.nombre_documento,
          url: item.url_archivo,
          nameFile: item.nombre_documento,
          isExternal: true,
        }));

        const combinedSuggestions = [...internalSuggestionData, ...suggestionData];
        setSuggestions(combinedSuggestions);
      } catch (error) {
        console.error('Error fetching search suggestions:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  useLayoutEffect(() => {
    const activeDefault = () => {
      const active = document.querySelector('.strikingDash-top-menu a.active');
      if (active) {
        const megaMenu = active.closest('.megaMenu-wrapper');
        const hasSubMenuLeft = active.closest('.has-subMenu-left');
        if (!megaMenu) {
          active.closest('ul').previousSibling?.classList.add('active');
          if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling?.classList.add('active');
        } else {
          megaMenu.previousSibling?.classList.add('active');
        }
      }
    };
    window.addEventListener('load', activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const addParentActive = (event) => {
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const { currentTarget } = event;
    const megaMenu = currentTarget.closest('.megaMenu-wrapper');
    const hasSubMenuLeft = currentTarget.closest('.has-subMenu-left');
    const parentUl = currentTarget.closest('ul');

    if (megaMenu) {
      megaMenu.previousSibling?.classList.add('active');
    } else {
      parentUl.previousSibling?.classList.add('active');
      if (hasSubMenuLeft) {
        hasSubMenuLeft.closest('ul').previousSibling?.classList.add('active');
      }
    }
  };

  const { rol } = useSelector((state) => state.auth);
  const userRoles = rol.map((rolItem) => rolItem.name);

  const checkPermission = (roles) => userRoles.some((userRole) => roles.includes(userRole));

  return (
    <TopMenuStyle className={classBgColor}>
      <div className="strikingDash-top-menu">
        <ul>
          {menuItems.map(
            (item, index) =>
              checkPermission(item.roles) && (
                <li key={index} className={item.subMenu ? 'has-subMenu' : ''}>
                  <Link to={item.path} className="parent" onClick={addParentActive}>
                    {item.name}
                  </Link>
                  {item.subMenu && (
                    <ul className="subMenu">
                      {item.subMenu.map(
                        (subItem, subIndex) =>
                          checkPermission(subItem.roles) && (
                            <li key={subIndex}>
                              <NavLink to={subItem.path} onClick={addParentActive}>
                                {subItem.name}
                              </NavLink>
                            </li>
                          ),
                      )}
                    </ul>
                  )}
                </li>
              ),
          )}
          <li className="search-container">
            <Search
              value={searchTerm}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              placeholder="Buscar"
              prefix={<SearchOutlined />}
              className="search-input"
            />
            {suggestions.length > 0 && (
              <ul className="suggestions">
                {suggestions.map((suggestion, index) => (
                  <li key={index}>
                  {suggestion.isExternal ? (
                      <a
                        href="#"
                        onClick={(e) => handleDownload(suggestion.url,suggestion.nameFile, e)}
                        className="resultadoSearch"
                      >
                        {suggestion.word}
                      </a>
                    ) : (
                      <a
                        href={suggestion.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="resultadoSearch"
                      >
                        {suggestion.word}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

TopMenu.propTypes = {
  classBgColor: propTypes.string,
};

export default TopMenu;
