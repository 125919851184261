import axiosInstance from "../axiosInstance";

function objectToQueryString(obj) {
    if (!obj) return ''

    return Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join('&');
  }

export function fetchDataNotification (page, filter) {
    const filterQueryString = objectToQueryString(filter);
    let queryString = ''
    if (page) queryString += `page=${page}`
    if (filterQueryString) queryString += queryString ? `&${filterQueryString}` : filterQueryString


    return axiosInstance
        .get(`/usuarios/actual/notificaciones${queryString ? `?${queryString}` : ''}`)
        .then((response) => {
            return response?.data
        })
}


  export function updateNotification(id, formdata) {
    return axiosInstance
      .post(`/usuarios/actual/notificaciones/${id}`, formdata)
      .then((response) => console.log('response post', response));
  }