import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

export function useRenderUtils(rtl) {
   const renderThumb = useCallback(({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }, [])

  const renderTrackVertical = useCallback(() => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  }, [rtl]);

  const renderView = useCallback(({ style, ...props }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  }, [rtl]);

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  }

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  return [renderView, renderThumb, renderTrackVertical]
}


