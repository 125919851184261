import React, {useCallback} from 'react'
import { useSelector} from 'react-redux';
import { Badge } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

import { AtbdTopDropdwon } from './auth-info-style';
import { useRenderUtils } from './useRenderUtils'
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';

function NotificationBox() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { rtl, items, totalItems } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      items: state.notifications.data,
      totalItems: state.notifications.total
    };
  });
  const [renderView, renderThumb, renderTrackVertical] = useRenderUtils(rtl);
  const handleNotiSelected = useCallback((id) => {
    history.push(`/admin/notificaciones/inbox/${id}`);
  }, [history]);

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">Notificaciones</span>
        <Badge className="badge-success" count={totalItems} />
      </Heading>
      <Scrollbars
        autoHeight
        autoHide={0}
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <ul className="atbd-top-dropdwon__nav notification-list">
          {items.map((item) => (
            <li key={item.id}>
              <a href="" target="_self" onClick={() => handleNotiSelected(item.id)}>
                <div className="atbd-top-dropdwon__content notifications">
                  <div className="notification-icon bg-primary">
                    <FeatherIcon icon="hard-drive" />
                  </div>
                  <div className="notification-content d-flex">
                    <div className="notification-text">
                      <Heading as="h5">
                        <span>{item.data.titulo}</span> {item.data.descripcion}
                      </Heading>
                    </div>
                    <div className="notification-status">
                      <Badge dot />
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </Scrollbars>
      <Link className="btn-seeAll" to={`${path}/notificaciones/inbox`}>
        Ver todas las notificaciones
      </Link>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge dot offset={[-8, -5]}>
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
}

export default NotificationBox;
