import Cookies from 'js-cookie';
import actions from './actions';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, setUser,rolBegin, rolSuccess, rolErr, setRol } = actions;

const setUserAction = (user) => {
  return (dispatch) => {
    dispatch(setUser(user));
  };
};

const login = (userObject) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
        Cookies.set('logedIn', true);
        // console.log('OBJEOT USUARIO  REDUX');
        // console.log(userObject);
        dispatch(setUser(userObject));
        return dispatch(loginSuccess(true));
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const setrol = (rolObject) => {
  return async (dispatch) => {
    try {
       dispatch(rolBegin());
        // console.log('OBJEOT ROL EN  REDUX:');
        // console.log(rolObject);
        dispatch(setRol(rolObject));
        return dispatch(rolSuccess(true));
    } catch (err) {
      dispatch(rolErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      dispatch(setUser({}));
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, setrol, setUserAction };
