import React, { lazy } from 'react';
import {  Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../../components/utilities/auth-info/protectedroute';

const ComOperamos = lazy(() => import('../../container/operation/HowOperate'));


export const UrlOperamos = `/admin/como-operamos`

function OperationRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/como-operamos`} component={ComOperamos}  allowedRoles={['user']}/>

    </Switch>
  );
}

export default OperationRoute;