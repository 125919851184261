import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { getNotifications } from '../redux/notifications/actionCreator';

export function useGetNotifications() {
  const dispatch = useDispatch();
  const fetchData = useCallback(() => {
    dispatch(getNotifications());
    console.log('hooks.js/useGetNotifications.js');
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
}
