import axiosInstance from '../axiosInstance';

export function TestFunction() {
  return Promise.resolve([]);
}

export function fetchDataGuets(page, consulta, sortField, sortOrder, numSolicitud) {
  const params = new URLSearchParams({
    page,
    consulta,
    ...(sortField && { sortField }),
    ...(sortOrder && { sortOrder }),
    ...(numSolicitud && { num_solicitud: numSolicitud }),
  }).toString();
  return axiosInstance.get(`/gastos-invitado?${params}`).then((response) => response?.data);
}

export function fetchOwnDataGuets(page, consulta) {
  return axiosInstance
    .get(`/gastos-invitado/missolicitudes?page=${page}&consulta=${consulta}`)
    .then((response) => response?.data);
}

export async function ConfirmEdition(id) {
  const response = await axiosInstance.post(`/gastos-invitado/${id}/confirmar-edicion/viajero`, {
    value: 1,
    _method: 'PUT',
  });
  return response?.data?.data;
}

export function saveConfirmEdicionAsistente(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/confirmar-edicion/asistente`, formdata)
    .then((response) => console.log('response post', response));
}

export function fetchSearchSuggestions(keyword, page = 1) {
  return axiosInstance.get(`/sgc/search?keyword=${keyword}&page=${page}`).then((response) => response.data);
}

export function getInvestigadores(id) {
  return axiosInstance.get(`/gastos-viaje-recurso/${id}`).then((response) => response?.data);
}

export function saveConfirmEdicion(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/confirmar-edicion/viajero`, formdata)
    .then((response) => console.log('response post', response));
}

export function saveGuestRequest(id, formdata) {
  const endpoint = `/gastos-invitado/${id}`;

  return axiosInstance
    .post(endpoint, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => console.log('response post', response))
    .catch((error) => console.error('Error in exportRequest:', error));
}

export function savePerson(formdata, personaId = null) {
  let url = `/adm-persona`;
  if (personaId > 0) {
    url = `/adm-persona/${personaId}`;
  }
  const responsePost = axiosInstance
    .post(url, formdata)
    .then((response) => {
      return { status: true, response: response.data };
    })
    .catch((error) => {
      return { status: false, error };
    });
  return responsePost;
}

export async function sendAprovalContableGuest(id, formdata) {
  const response = await axiosInstance.post(`gastos-invitado/${id}/aprobado-contabilidad`, formdata);
  return response?.data;
}

export async function sendAprovalDiscountGuest(id, formdata) {
  console.log(id);
  const response = await axiosInstance.post(`gastos-invitado/${id}/anticipo/aprobacion-descuento`, formdata);
  return response?.data;
}

export function sendResourcesFinancieraGuest(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/car-fedec`, formdata)
    .then((response) => console.log('response post', response));
}

export function sendResourcesAprovalFinancieraGuest(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/car-fedec/aprobacion`, formdata)
    .then((response) => console.log('response post', response));
}

export function fetchDataFormGuest(id) {
  console.log(id);
  return axiosInstance.get(`/gastos-invitado/${id}`).then((response) => response?.data);
}

export async function fetchGuestData(id) {
  const response = await axiosInstance.get(`/gastos-invitado/${id}`);
  console.log(response);
  return response.data.data;
}

export function saveRequestResourcesDirectivo(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/aprobado-directivos`, formdata)
    .then((response) => console.log('response post', response));
}

export function getDependencies() {
  return axiosInstance.get('/listaadmsubcomponente').then((response) => response?.data);
}

export async function fetchDataSubAcuerdo(id) {
  const response = await axiosInstance.get(`/listas/subacuerdo/${id}`);
  return response.data;
}

export function getUsers() {
  return axiosInstance.get('/users/asistente').then((response) => response?.data);
}

export function getDirectores() {
  return axiosInstance.get('/users/directivo').then((response) => response?.data);
}

export async function getResoluciones() {
  const response = await axiosInstance.get('/listas/resoluciones').then((response) => response?.data);
  return response;
}

export async function getDataChart() {
  const response = await axiosInstance.get('/resumen-solicitud-por-estado').then((response) => response?.data);
  return response;
}

export function getSourceData(value) {
  return axiosInstance.get(`/proyectofuente/${value}`).then((response) => response?.data);
}

export function getProductMeta(valueTable, value) {
  return axiosInstance.get(`/proyectoproducto/${valueTable}/${value}`).then((response) => response?.data);
}

export function getTypeExpense() {
  return axiosInstance.get('/listas/tipo-gasto').then((response) => response?.data);
}

export async function fetchDataAcuerdo1(id) {
  const response = await axiosInstance.get(`/listas/subacuerdo/${id}/car`);
  return response.data;
}

export function getCategories() {
  return axiosInstance.get('/listaadmcategoria').then((response) => response?.data);
}

export function saveRequestResourcesLogisticaGuest(id, formdata, tipo) {
  const responsePost = axiosInstance
    .post(`/gastos-invitado/${id}/logistica/cotizacion/${tipo}/enviar-correo`, formdata)
    .then((response) => console.log('response post', response));
  return responsePost;
}

export function saveRequestResourcesCausacionGuest(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/causacion`, formdata)
    .then((response) => console.log('response post', response));
}

export function saveRequestResourcesSeniorFinalGuest(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/egreso/aprobacion`, formdata)
    .then((response) => console.log('response post', response));
}

export function saveRequestResourcesPagoGuest(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/egreso`, formdata)
    .then((response) => console.log('response post', response));
}

export async function fetchResourcesLogisticGuest(id, tipo) {
  const response = await axiosInstance.get(`/gastos-invitado/${id}/logistica-masiva/cotizacion/${tipo}`);
  console.log(response);
  return response.data.data;
}
export function saveRequestResourcesConfirmaLogisticaGuest(id, formdata, tipo) {
  return axiosInstance.post(`/gastos-invitado${id}/logistica/cotizacion/${tipo}`, formdata).then((response) => {
    return response.data;
  });
}

export function saveRequestResourcesCorreoTiquetesGuest(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/tiquetes/cotizacion/enviar-correo`, formdata)
    .then((response) => console.log('response post', response));
}

export function saveRequestResourcesTiquetesCotizaGuest(id, formdata) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/tiquetes/cotizacion`, formdata)
    .then((response) => console.log('response post', response));
}

export function saveRequestResourcesTiquetesCotizaGuestNew(id, formdata, idTiquete) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/tiquetes/cotizacion/${idTiquete}`, formdata)
    .then((response) => console.log('response post', response));
}

export function saveRequestTicket(id, formdata, idTiquete) {
  return axiosInstance
    .post(`/gastos-invitado/${id}/tiquetes/cotizacion/${idTiquete}`, formdata)
    .then((response) => console.log('response post', response));
}

// gastos-viaje-recurso/{id}/tiquetes/cotizacion/{idTiquete}

export function saveRequestResourcesCorreoLogistica(id, formdata, tipo) {
  const responsePost = axiosInstance
    .post(`/gastos-invitado/${id}/logistica-masiva/cotizacion/${tipo}/enviar-correo`, formdata)
    .then((response) => console.log('response post', response));
  return responsePost;
}

export function saveRequestNovedadGuest(id, formdata) {
  return axiosInstance.post(`/gastos-invitado/${id}/novedades`, formdata).then((response) => {
    return response.data;
  });
}
