const actions = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_NOTIFICATIONS_ERR: 'SET_NOTIFICATIONS_ERR',

  setNotifications: ({ data, total }) => {
    return {
      type: actions.SET_NOTIFICATIONS,
      payload: { data, total}
    };
  },

  setNotificationsErr: (err) => {
    return {
      type: actions.SET_NOTIFICATIONS_ERR,
      err,
    };
  },
};

export default actions;
