import actions from './actions';
import { fetchDataNotification } from '../../services/notifications'

const { setNotifications: setNotificationsAction, setNotificationsErr } = actions;

const getNotifications = () => {
  return async (dispatch) => {
    try {
      const { data } = await fetchDataNotification()
      dispatch(setNotificationsAction({ data, total: data.length }));
    } catch (err) {
      dispatch(setNotificationsErr(err));
    }
  };
};

const setNotificationsError = (err) => {
    return (dispatch) => {
      dispatch(setNotificationsErr(err));
    };
}

export { getNotifications, setNotificationsError };
