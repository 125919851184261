import React from 'react';
import PropTypes from 'prop-types';

export const IconHumble = ({ className }) => {
  return (
    <svg
      id="Capa_2"
      className={className}
      data-name="Capa 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 759.44 321.02"
    >
      <defs>
        {/* <style>
          {
            '\{\n        fill: #333;\n        stroke: #333;\n        stroke-linecap: round;\n        stroke-linejoin: round;\n        stroke-width: .5px;\n      }\n    '
          }
        </style> */}
      </defs>
      <g>
        <path d="M241.95,176.27l-32.95-11.79c-5.22,9.16-14.34,15.8-25.09,17.66l5.63,45.37c4.92.16,9.46,1.85,13.15,4.61l42.83-38.33c-2.53-3.72-4.01-8.21-4.01-13.04,0-1.53.15-3.03.44-4.48Z" />
        <path d="M92.78,185.48c-8.97,0-16.26,7.3-16.26,16.26s7.3,16.26,16.26,16.26,16.26-7.3,16.26-16.26-7.3-16.26-16.26-16.26Z" />
        <path d="M202.51,66.75c0,8.97,7.3,16.26,16.26,16.26s16.26-7.3,16.26-16.26c0-3.01-.84-5.82-2.26-8.24h-28c-1.43,2.42-2.26,5.23-2.26,8.24Z" />
        <path d="M166.74,243.3l-55.43-27.52c-4.25,5.6-10.98,9.23-18.54,9.23-12.83,0-23.26-10.44-23.26-23.26,0-11.69,8.67-21.39,19.92-23.02l2.44-49.48c-9.98-2.62-17.36-11.71-17.36-22.5,0-12.83,10.44-23.26,23.26-23.26,8.96,0,16.76,5.1,20.64,12.55l77.41-25.49c-.2-1.23-.31-2.5-.31-3.79,0-2.9.54-5.67,1.51-8.24h-108.04c-14.9,0-26.97,12.08-26.97,26.97v144.55c0,14.9,12.08,26.97,26.97,26.97h77.39c-.56-1.99-.86-4.09-.86-6.26,0-2.6.44-5.11,1.23-7.45Z" />
        <path d="M248.51,180.75c0,8.97,7.3,16.26,16.26,16.26,1.73,0,3.39-.28,4.96-.78v-30.97c-1.56-.5-3.23-.78-4.96-.78-8.97,0-16.26,7.3-16.26,16.26Z" />
        <path d="M230.1,87.06l28.52,71.26c1.96-.54,4.03-.83,6.16-.83,1.7,0,3.36.19,4.96.54v-72.54c0-14.9-12.08-26.97-26.97-26.97h-2.23c.97,2.56,1.51,5.34,1.51,8.24,0,8.72-4.82,16.33-11.94,20.31Z" />
        <path d="M205.04,250.75c0-8.97-7.3-16.26-16.26-16.26s-16.26,7.3-16.26,16.26c0,2.22.45,4.33,1.26,6.26h30.01c.81-1.93,1.26-4.04,1.26-6.26Z" />
        <path d="M177.78,117.82c-15.95,0-28.92,12.97-28.92,28.92s12.97,28.92,28.92,28.92,28.92-12.97,28.92-28.92-12.97-28.92-28.92-28.92Z" />
        <path d="M147.27,127.8c6.35-10.18,17.64-16.98,30.5-16.98,4.53,0,8.86.85,12.85,2.39l14.89-27.37c-3.17-2.21-5.76-5.18-7.52-8.65l-77.32,25.47c.24,1.33.37,2.69.37,4.09,0,2.05-.27,4.04-.77,5.94l27,15.12Z" />
        <path d="M114.04,106.75c0-8.97-7.3-16.26-16.26-16.26s-16.26,7.3-16.26,16.26,7.3,16.26,16.26,16.26,16.26-7.3,16.26-16.26Z" />
        <path d="M148.56,167.61l-35.27,23.17c1.76,3.27,2.75,7,2.75,10.97,0,2.77-.49,5.42-1.38,7.88l55.3,27.45c3.06-4.2,7.49-7.34,12.63-8.76l-5.67-45.68c-11.68-.28-21.99-6.15-28.36-15.04Z" />
        <path d="M213.7,146.75c0,3.95-.65,7.76-1.84,11.32l32.45,11.62c1.88-3.46,4.61-6.38,7.91-8.51l-28.68-71.66c-1.54.32-3.13.49-4.77.49-2.43,0-4.77-.38-6.98-1.07l-14.91,27.41c10.09,6.37,16.81,17.61,16.81,30.39Z" />
        <path d="M141.85,146.75c0-4.46.82-8.72,2.31-12.66l-26.71-14.95c-3.95,6.25-10.78,10.5-18.61,10.85l-2.41,48.79c4.88.78,9.26,3.08,12.64,6.4l35.97-23.64c-2.05-4.51-3.2-9.52-3.2-14.79Z" />
        <path d="M250.28,198.92l-42.67,38.19c2.78,3.83,4.43,8.55,4.43,13.64,0,2.17-.31,4.27-.86,6.26h31.58c14.9,0,26.97-12.08,26.97-26.97v-26.56c-1.6.35-3.26.54-4.96.54-5.48,0-10.51-1.91-14.49-5.09Z" />
      </g>
      <g>
        <path d="M304.06,173.45v-.32c2.62-.93,4.34-2.16,5.17-3.68.83-1.52,1.24-3.96,1.24-7.33v-34.11c0-3.28-.41-5.67-1.24-7.17-.83-1.49-2.55-2.71-5.17-3.64v-.32h40.52v13.61h-.4c-2.7-4.32-4.84-7.13-6.45-8.41s-4.02-1.92-7.25-1.92h-7.73v22.62h8.05c2.24,0,4.04-.41,5.41-1.22s2.79-2.37,4.28-4.67h.4v14.89h-.4c-1.47-2.3-2.9-3.83-4.28-4.59-1.39-.76-3.19-1.14-5.41-1.14h-8.05v24.1h6.97c2.35,0,4.19-.11,5.53-.32,1.33-.21,2.72-.84,4.16-1.88,1.47-1.04,2.71-2.15,3.72-3.32,1.01-1.17,2.7-3.22,5.04-6.13h.4l-1.64,14.93h-42.88Z" />
        <path d="M375.6,130.65c2.91,0,5.58.76,8.01,2.28,2.43,1.5,3.65,3.31,3.65,5.45s-.58,3.82-1.74,5.04c-1.16,1.23-2.62,1.87-4.36,1.92-1.75,0-3.21-.54-4.4-1.63-1.19-1.09-1.78-2.35-1.78-3.78s.36-2.76,1.07-3.98c.71-1.25,1.6-2.15,2.66-2.71-.77-.11-1.3-.16-1.59-.16-3.94,0-6.98,1.41-9.11,4.23-2.13,2.82-3.2,7.04-3.2,12.66s1.28,9.83,3.83,12.7c2.56,2.85,5.78,4.27,9.67,4.27s7.34-1.09,10.41-3.28l.52.32c-2.48,3.68-5.03,6.33-7.65,7.96-2.62,1.6-5.64,2.43-9.08,2.48-5.41.03-10.01-1.91-13.8-5.81-3.79-3.9-5.69-8.89-5.72-14.97,0-3.76.69-7.18,2.08-10.25,1.36-3.07,3.14-5.5,5.32-7.29,4.4-3.66,9.47-5.49,15.21-5.49Z" />
        <path d="M390.62,152.41c0-6.25,2.22-11.44,6.67-15.55,4.47-4.11,9.7-6.18,15.69-6.21,5.98.03,11.2,2.1,15.65,6.21,4.45,4.11,6.67,9.3,6.67,15.55s-2.2,11.48-6.59,15.69c-4.39,4.23-9.65,6.35-15.77,6.35s-11.38-2.12-15.77-6.35c-4.37-4.21-6.55-9.44-6.55-15.69ZM412.94,172.01c3.45,0,5.99-1.88,7.62-5.65,1.63-3.77,2.45-8.43,2.45-13.99s-.87-10.15-2.61-13.79c-1.79-3.66-4.29-5.49-7.5-5.49s-5.68,1.82-7.42,5.45-2.59,8.31-2.57,14.03c0,7.48,1.38,12.97,4.13,16.47,1.58,1.98,3.54,2.97,5.9,2.97Z" />
        <path d="M455.33,171.97c2.08,0,3.76-.59,5.04-1.76,1.28-1.2,1.92-2.74,1.92-4.6s-.72-3.4-2.16-4.6-3.2-2.26-5.29-3.16c-2.08-.91-4.15-1.9-6.21-2.96-2.06-1.07-3.8-2.58-5.24-4.52-1.44-1.95-2.16-4.23-2.16-6.85,0-3.71,1.49-6.78,4.48-9.21,3.02-2.43,6.59-3.64,10.73-3.64s8.17.73,12.09,2.2v10.65h-.32c-4.59-6.94-8.63-10.41-12.13-10.41-1.76,0-3.24.52-4.44,1.56s-1.8,2.42-1.8,4.12.75,3.18,2.24,4.4c1.5,1.2,3.31,2.26,5.45,3.16,2.14.91,4.26,1.91,6.37,3,2.11,1.09,3.91,2.64,5.41,4.64s2.26,4.34,2.28,7.01c0,4.14-1.62,7.41-4.84,9.81s-7.3,3.6-12.21,3.6-9.29-.87-13.13-2.6l-.4-12.37h.4c4.75,8.35,9.4,12.53,13.93,12.53Z" />
        <path d="M496.73,173.45h-21.94v-.32c2.12-.93,3.52-2.15,4.21-3.64s1.03-3.9,1.03-7.21v-16.46c0-3.63-.37-6.14-1.11-7.53-.74-1.39-2.41-2.6-5.01-3.64v-.32c4.96-.32,10.73-1.55,17.3-3.68h.32v31.63c0,3.28.34,5.67,1.03,7.17s2.08,2.72,4.17,3.68v.32ZM491.76,121.04c0,1.68-.62,3.14-1.87,4.36-1.24,1.23-2.72,1.83-4.42,1.8-1.7,0-3.18-.6-4.42-1.8-1.22-1.17-1.83-2.62-1.83-4.32s.62-3.16,1.87-4.36c1.24-1.2,2.72-1.8,4.44-1.8s3.17.6,4.38,1.8c1.23,1.2,1.85,2.64,1.85,4.32Z" />
        <path d="M515.58,171.97c2.08,0,3.76-.59,5.04-1.76,1.28-1.2,1.92-2.74,1.92-4.6s-.72-3.4-2.16-4.6-3.2-2.26-5.29-3.16c-2.08-.91-4.15-1.9-6.21-2.96-2.06-1.07-3.8-2.58-5.25-4.52-1.44-1.95-2.16-4.23-2.16-6.85,0-3.71,1.5-6.78,4.48-9.21,3.02-2.43,6.59-3.64,10.73-3.64s8.17.73,12.09,2.2v10.65h-.32c-4.59-6.94-8.63-10.41-12.13-10.41-1.76,0-3.24.52-4.44,1.56-1.2,1.04-1.8,2.42-1.8,4.12s.75,3.18,2.24,4.4c1.5,1.2,3.31,2.26,5.45,3.16,2.14.91,4.26,1.91,6.37,3,2.11,1.09,3.91,2.64,5.41,4.64s2.26,4.34,2.28,7.01c0,4.14-1.62,7.41-4.84,9.81s-7.3,3.6-12.21,3.6-9.29-.87-13.13-2.6l-.4-12.37h.4c4.75,8.35,9.4,12.53,13.93,12.53Z" />
        <path d="M550.5,174.1c-7.93,0-11.89-4.2-11.89-12.61v-25.42h-6.97v-.48c6.89-2.83,12.84-7.67,17.86-14.53h.6v10.61h10.65v4.96h-10.65v24.3c0,2.3.63,4.12,1.88,5.49s2.9,2.04,4.92,2.04,3.78-.49,5.25-1.48l.32.4c-1.15,1.6-2.83,3.12-5.04,4.56s-4.52,2.16-6.93,2.16Z" />
        <path d="M580.55,174.46c-5.8.03-10.4-1.91-13.79-5.81s-5.1-8.98-5.13-15.25c0-6.27,2-11.62,5.99-16.06,3.99-4.43,8.59-6.66,13.81-6.69,5.21,0,9.31,1.39,12.29,4.16,2.98,2.78,4.47,6.94,4.47,12.49v3.36h-24.74c0,5.26,1.31,9.14,3.93,11.65,2.62,2.51,5.96,3.79,10.02,3.84,4.06,0,7.7-1.2,10.93-3.6l.4.32c-4.8,7.71-10.86,11.57-18.18,11.57ZM581.23,133.1c-2.41,0-4.31,1.52-5.7,4.56-1.39,3.04-2.09,6.75-2.09,11.13l14.57-1.32c0-4.78-.54-8.37-1.61-10.77-1.07-2.4-2.8-3.6-5.18-3.6Z" />
        <path d="M675.58,173.45h-21.98v-.32c2.13-.93,3.55-2.15,4.24-3.64.69-1.5,1.03-3.9,1-7.21v-17.94c0-2.51-.65-4.44-1.96-5.81-1.31-1.39-3.11-2.08-5.41-2.08s-4.8.69-7.53,2.08c.61,1.92.91,4.04.88,6.37v17.38c0,3.28.35,5.67,1.04,7.17.69,1.5,2.09,2.72,4.2,3.68v.32h-21.98v-.32c2.13-.93,3.55-2.15,4.24-3.64.69-1.5,1.03-3.9,1-7.21v-17.94c0-2.56-.6-4.51-1.8-5.85-1.17-1.36-2.88-2.04-5.11-2.04s-4.56.63-6.99,1.88v23.94c0,3.28.34,5.67,1.03,7.17.69,1.5,2.08,2.72,4.17,3.68v.32h-21.94v-.32c2.12-.93,3.52-2.15,4.21-3.64.69-1.5,1.03-3.9,1.03-7.21v-16.46c0-3.63-.37-6.14-1.12-7.53-.75-1.39-2.43-2.6-5.04-3.64v-.32c4.81-.32,10.53-1.55,17.17-3.68h.32v6.65c1.62-1.63,3.72-3.14,6.28-4.52,2.56-1.39,5.02-2.07,7.39-2.04,5.46,0,9.07,2.35,10.83,7.05,1.55-1.63,3.76-3.22,6.65-4.76,2.88-1.55,5.85-2.34,8.89-2.36,3.04,0,5.65,1.16,7.81,3.48,2.13,2.32,3.2,5.91,3.2,10.77v17.38c0,3.28.35,5.67,1.04,7.17.69,1.5,2.09,2.72,4.2,3.68v.32Z" />
        <path d="M709.97,174.26c-1.87,0-3.43-.47-4.68-1.41-1.25-.94-1.99-2.29-2.2-4.04-4.3,3.74-8.48,5.62-12.55,5.65-3.05,0-5.59-.92-7.61-2.76s-3.03-4.2-3.03-7.09c-.03-3.6,1.92-6.79,5.86-9.57,3.93-2.78,9.68-4.56,17.25-5.37v-4.79c0-3.35-.96-6.08-2.87-8.17-1.91-2.09-4.43-3.15-7.55-3.18-2.05,0-4.04.6-5.98,1.81,1.68.32,3.05,1.01,4.09,2.06,1.05,1.05,1.56,2.3,1.53,3.75,0,1.45-.56,2.7-1.69,3.75-1.15,1.05-2.51,1.57-4.06,1.57s-2.9-.52-4.04-1.56c-1.14-1.04-1.71-2.3-1.71-3.76,0-2.91,1.82-5.38,5.46-7.41,3.64-2.03,7.81-3.06,12.51-3.08,10.5,0,15.76,4.89,15.78,14.67v17.15c0,3,.52,4.97,1.56,5.94,1.04.96,2.79,1.58,5.25,1.84v.31c-4.16,2.46-7.94,3.68-11.33,3.68ZM700.81,152.39c-2.66,0-4.89.67-6.7,2.02-1.81,1.34-2.71,3.62-2.71,6.82,0,2.21.78,3.99,2.35,5.37,1.57,1.37,3.18,2.06,4.83,2.06s3.12-.36,4.43-1.09v-14.92c-.77-.16-1.5-.24-2.19-.24Z" />
      </g>
      <g>
        <path d="M309.73,187.32h1.84v11.55h16.54v-11.55h1.84v25.2h-1.84v-12.15h-16.54v12.15h-1.84v-25.2Z" />
        <path d="M362.46,203.07c0,5.81-4.42,9.9-10.39,9.9s-10.42-4.09-10.42-9.9v-15.75h1.84v15.68c0,4.95,3.64,8.44,8.59,8.44s8.55-3.49,8.55-8.44v-15.68h1.84v15.75Z" />
        <path d="M374.16,187.32h1.84l9.94,13.43,9.9-13.43h1.84v25.2h-1.73v-22.61l-9.45,12.86h-1.16l-9.45-12.86v22.61h-1.72v-25.2Z" />
        <path d="M409.67,187.32h9.67c4.13,0,7.09,2.66,7.09,6.41,0,2.62-1.57,4.84-4.09,5.77,3.11.79,4.99,3.23,4.99,6.3,0,4.05-3.11,6.71-7.39,6.71h-10.27v-25.2ZM411.47,198.94h7.69c3.19,0,5.48-2.1,5.48-5.06s-2.29-5.1-5.48-5.1h-7.69v10.16ZM411.47,211.06h8.25c3.41,0,5.77-2.21,5.77-5.4s-2.36-5.36-5.77-5.36h-8.25v10.76Z" />
        <path d="M447.77,186.87c7.08,0,12.07,5.4,12.07,13.05s-4.99,13.05-12.07,13.05-12.11-5.4-12.11-13.05,4.99-13.05,12.11-13.05ZM447.77,211.43c5.96,0,10.2-4.8,10.2-11.51s-4.24-11.51-10.2-11.51-10.24,4.8-10.24,11.51,4.24,11.51,10.24,11.51Z" />
        <path d="M469.86,187.32h1.84v23.66h12.86v1.54h-14.7v-25.2Z" />
        <path d="M493.52,187.32h7.72c7.54,0,12.67,5.1,12.67,12.6s-5.14,12.6-12.67,12.6h-7.72v-25.2ZM501.06,188.86h-5.7v22.12h5.7c6.49,0,10.99-4.54,10.99-11.06s-4.5-11.06-10.99-11.06Z" />
        <path d="M528.84,188.86h-9.15v-1.54h20.14v1.54h-9.15v23.66h-1.84v-23.66Z" />
      </g>
    </svg>
  );
};

IconHumble.propTypes = {
  className: PropTypes.string,
};
