import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Charts = lazy(() => import('../../container/widgets/Charts'));

function WidgetsRoute() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/chart`} component={Charts} />
    </Switch>
  );
}

export default WidgetsRoute;
