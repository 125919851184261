import React, { useState, useCallback, useLayoutEffect,useEffect } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import PropTypes from 'prop-types';

export const DEFAULT_COLOR = '#0088FE'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g className='test'>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.nombre}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

renderActiveShape.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  midAngle: PropTypes.number.isRequired,
  innerRadius: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired,
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  payload: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
};

const RADIAN = Math.PI / 180;
function renderCustomizedLabel({ cx, cy, midAngle, innerRadius, outerRadius, percent }) {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
}

function ReChartPie({ mainItems, secondaryItems, onSelectCell }) {
  const [state, setState] = useState({
    activeIndex: 0,
    responsive: 0,
    selectedCells: [],
    dataSubProceso: [],
  });

  const { responsive, activeIndex } = state;
  const [selectedCells, setSelectedCells] = useState([]);

  useLayoutEffect(() => {
    function updateSize() {
      const element = document.querySelector('.recharts-wrapper');
      const width =
        element !== null
          ? element.closest('.ant-card-body').clientWidth
          : document.querySelector('.ant-card-body').clientWidth;
      setState({ responsive: width, activeIndex });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [activeIndex]);

  const onPieEnter = useCallback((data, index) => {
    setState({
      ...state,
      activeIndex: index,
    });
  }, [state]);


  renderCustomizedLabel.propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    midAngle: PropTypes.number.isRequired,
    innerRadius: PropTypes.number.isRequired,
    outerRadius: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  };

  const handleCellClick = useCallback((entry, index) => {
    setSelectedCells([index]);
    onSelectCell(entry)
  }, [onSelectCell]);

  const getFillColor = useCallback((entry, index) => {
    if (selectedCells.length === 0) {
      return entry.color;
    }

    return selectedCells.includes(index) ? entry.color : '#999999';
  }, [selectedCells]);

  const getInnerFillColor = useCallback((entry, index) => {
    if (selectedCells.length === 0) {
      return mainItems[index].color || DEFAULT_COLOR;
    }

    const selectData = secondaryItems[selectedCells[0]];

    return selectData && selectData.macro === entry.nombre ? selectData.color || DEFAULT_COLOR : '#999999';
  }, [mainItems, secondaryItems, selectedCells]);

  useEffect(() => {
    console.log(responsive - (10 * responsive) / 200)
  }, [responsive])
  
  return (
    <>
      <PieChart
        width={responsive - (10 * responsive) / 200}
        height={500}
        onMouseEnter={onPieEnter}
                // style={ {"transform":responsive >= 400 ?"scale(0.6)":"none" }}

      >
        <Pie
          data={mainItems}
          dataKey="value"
          cx={responsive / 2 - 30}
          cy={280}
          outerRadius={100}
          innerRadius={80}
        >
          {mainItems.map((entry, index) => (
            <Cell key={`cell-${entry.name}`} fill={getInnerFillColor(entry, index)} />
          ))}
        </Pie>
        <Pie
          data={secondaryItems}
          cx={responsive / 2 - 30}
          cy={280}
          innerRadius={120}
          outerRadius={190}
          paddingAngle={5}
          dataKey="value"
          label={({ name }) => name}
          onClick={(entry, index) => handleCellClick(entry, index)}
        >
          {secondaryItems.map((entry, index) => (
            <Cell className="cursor" key={`cell-${entry.name}`} fill={getFillColor(entry, index)} />
          ))}

          {secondaryItems.map((entry, index) => (
            <text
              key={`cell-${entry.nombre}`}
              x={200}
              y={100 + index * 30}
              textAnchor="middle"
              fill={getFillColor(entry, index)}
              onClick={() => handleCellClick(index)}
              style={{ cursor: 'pointer' }}
            >
              {entry.name}
            </text>
          ))}
        </Pie>
      </PieChart>
      <div className="leyend-content">
        {mainItems.map((entry) => (
          <p key={`leyend-${entry.nombre}`} className="leyend-chart">
            <span className="leyend-card" style={{ backgroundColor: entry.color || DEFAULT_COLOR }} />
            {entry.nombre}
          </p>
        ))}
      </div>
    </>
  );
}


ReChartPie.propTypes = {
  mainItems: PropTypes.array,
  secondaryItems: PropTypes.array,
  onSelectCell: PropTypes.func,
}
export default ReChartPie;
